var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cascader-item"
  }, [_c('div', {
    staticClass: "content-left"
  }, [_c('div', {
    staticClass: "content-wrap"
  }, _vm._l(_vm.options, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "item-style",
      class: {
        active: _vm.activeIdx === index
      },
      on: {
        "click": function click($event) {
          return _vm.select(item, index);
        }
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])]);
  }), 0)]), _vm.lists && _vm.lists.length ? _c('div', {
    staticClass: "content-right"
  }, [_c('CascaderItem', {
    attrs: {
      "options": _vm.lists,
      "selectedItems": _vm.selectedItems,
      "level": _vm.level + 1
    },
    on: {
      "change": _vm.change
    }
  })], 1) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };