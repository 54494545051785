export default {
  data: function data() {
    return {
      activeIdx: -1
    };
  },
  name: "CascaderItem",
  // 给组件起个名字，方便组件内部递归调用，即组件内部自己调用自己
  props: ["options", "selectedItems", "level"],
  computed: {
    lists: function lists() {
      // 根据内容value的变化显示列表，根据当前点击位置对应的level去获取要显示的列表
      return this.selectedItems[this.level] && this.selectedItems[this.level].children;
    }
  },
  methods: {
    select: function select(item, idx) {
      this.activeIdx = idx;
      // 处理CascaderItem组件内左侧列点击事件，item为当前点击的对象
      // 向上一级发射一个change事件，通知上层进行修改，并将当前点击的层级level和item传递过去
      this.$emit("change", {
        level: this.level,
        item: item
      });
    },
    change: function change(newValue) {
      // 向顶层传递数据改变信息
      this.$emit("change", newValue);
    }
  }
};